import React from "react";
import styled from "styled-components";

import Sarah from "./elements/Sarah";
import LogosLayerBottom from "./elements/LogosLayerBottom";
import LogosLayerMiddle from "./elements/LogosLayerMiddle";
import LogosLayerTop from "./elements/LogosLayerTop";

const Svg = styled.svg`
  display: block;
  overflow: visible;
`;

const CenterSarah = styled(Sarah)`
  display: none;
  ${({ theme }) => theme.media.tabletLandscape`
    display: unset;
    transform: translate(-300px, -83px) scale(1.4);
  `};
`;

const bottom = (x, y) => `translate3d(${-x * 0.008}px, ${-y * 0.009}px, 0)`;
const middle = (x, y) => `translate3d(${-x * 0.015}px, ${-y * 0.02}px, 0)`;
const top = (x, y) => `translate3d(${-x * 0.03}px, ${-y * 0.038}px, 0)`;

const PortProjectsWindow = ({ springProps }) => {
  return (
    <Svg viewBox="0 0 1678.836 503.798" xmlns="http://www.w3.org/2000/svg">
      <LogosLayerBottom
        style={{ transform: springProps.xy.interpolate(bottom) }}
      />
      <LogosLayerMiddle
        style={{ transform: springProps.xy.interpolate(middle) }}
      />
      <LogosLayerTop style={{ transform: springProps.xy.interpolate(top) }} />
      <CenterSarah />
    </Svg>
  );
};

export default PortProjectsWindow;
