import React from "react";

const Sarah = (props) => (
  <g {...props}>
    <ellipse fill="#D1D1D1" cx="820.209" cy="493.732" rx="225.517" ry="7.38" />
    <path
      fill="#C99465"
      d="M836.326,88.039c0,0,9.912,0.034,15.24,8.377c5.328,8.343-1.248,10.246,4.248,15.407
		c5.495,5.161-2.067,11.22,4.25,17.502c6.317,6.282-1.847,13.201-10.108,11.62c0,0-6.674,6.735-14.624,2.981
		c0,0-10.118,4.171-23.368-5.584l-4.802-3.806L836.326,88.039z"
    />
    <path
      fill="#C99465"
      d="M805.303,79.871c0,0-10.257-2.504-14.809,4.112c-4.552,6.615-3.232,5.139-9.996,6.901
		c-6.763,1.762-1.428,8.444-7.076,10.61c-2.884,1.106-4.479,2.286-5.336,4.001c-1.508,3.02,0.094,6.776,3.437,8.964
		c2.283,1.493,3.609,4.196,4.127,7.411c1.026,6.377,12.395,7.876,12.395,7.876s8.207,8.426,15.599,7.041
		c7.392-1.385,16.053-54.118,16.053-54.118L805.303,79.871z"
    />
    <path
      fill="#C99465"
      d="M844.707,91.832c0,0-0.674-10.641-15.065-15.839c-14.391-5.198-23.947,7.302-23.947,7.302l16.82,18.969
		L844.707,91.832z"
    />
    <path
      fill="#C99465"
      d="M848.393,117.008c-4.605,12.749-18.981,19.239-32.111,14.497c-13.129-4.742-21.245-19.945-16.64-32.693
		c4.605-12.749,20.186-18.216,33.315-13.474C846.087,90.08,852.998,104.259,848.393,117.008z"
    />
    <path
      fill="#F5CEBA"
      d="M848.571,182.14c0,0,29.762,37.065,36.662,45.787c6.901,8.723,36.796,35.835,37.992,36.851
		c1.195,1.016,20.98-3.362,20.78-0.832c-0.2,2.53-10.237,1.714-9.95,4.597c0.286,2.883,20.047,18.276,18.487,19.284
		c-2.92,1.886-12.452-8.959-13.649-7.822c-1.198,1.138,10.986,10.408,9.68,13.028c-1.306,2.62-10.724-9.846-12.042-9.465
		c-1.318,0.382,7.304,12.899,4.389,13.516c-1.582,0.335-7.399-11.314-8.737-9.981c-1.339,1.333,1.737,10.923-0.125,11.132
		c-1.862,0.21-1.504-8.375-5.056-12.29c-3.552-3.914-4.119-8.562-11.567-13.149c-7.449-4.587-36.027-26.174-47.688-39.911
		c-11.661-13.737-33.109-41.105-33.109-41.105L848.571,182.14z"
    />
    <path
      fill="#F5CEBA"
      d="M785.102,148.873c0,0-21.243-42.525-26.285-52.438c-5.042-9.914-9.316-49.629-9.74-51.14
		c-0.423-1.51,11.82-17.657,9.437-18.532c-2.382-0.875-5.834,8.585-8.334,7.12c-2.5-1.465-8.23-25.849-9.798-24.853
		c-2.933,1.865,2.939,15.056,1.405,15.668c-1.534,0.612-4.867-14.329-7.793-14.237c-2.926,0.092,4.466,13.857,3.569,14.895
		c-0.897,1.038-8.668-12.024-10.446-9.634c-0.965,1.298,7.188,11.449,5.418,12.108c-1.77,0.659-9.198-6.141-10.166-4.538
		c-0.969,1.604,6.981,4.866,9.053,9.728c2.073,4.862,6.058,7.319,7.114,16.002c1.056,8.684,1.217,44.738,8.827,61.071
		c7.609,16.333,23.514,47.254,23.514,47.254L785.102,148.873z"
    />
    <g>
      <path
        fill="#D8A08D"
        d="M768.101,491.816c-1.45,1.134-2.637,2.366-0.84,2.335l24.404-0.419l-11.696-8.902
			C772.389,488.732,770.474,489.959,768.101,491.816z"
      />
      <path
        fill="#047658"
        d="M807.906,391.134c-0.546-8.863-6.887-28.575-3.43-40.732c3.457-12.158,1.514-93.752,1.514-93.752
			l-20.898-6.909l-7.518,6.138c0,0-9.814,17.543-7.724,48.697c0.885,13.195,13.038,107.204,18.041,145.634
			c5.192,0.683,11.498-1.053,15.514-0.991C807.513,427.223,808.263,396.944,807.906,391.134z"
      />
      <path
        fill="#F5CEBA"
        d="M802.999,490.419c1.274-2.532-1.503-8.072-2.001-10.763c-0.339-1.829-0.402-5.044-0.346-8.391
			c0.009-0.043,0.598-10.516,2.752-22.046c-4.016-0.062-10.321,1.674-15.514,0.991c1.309,10.055,2.13,16.317,2.13,16.317h0.001
			c-0.269,4.236-0.906,10.215-2.387,11.97c-1.423,1.686-3.076,3.369-4.721,4.439c-0.649,0.421-2.449,1.64-2.944,1.894l11.696,8.902
			l8.606-0.148C800.271,493.585,801.726,492.952,802.999,490.419z"
      />
    </g>
    <g>
      <path
        fill="#047658"
        d="M825.896,253.397c0,0,13.868,16.629,16.915,31.017c2.733,12.903,2.491,25.923-0.303,41.317
			c-2.793,15.394-3.144,38.141,3.823,50.622c6.966,12.481,15.39,19.934,17.861,33.421c1.496,8.166,3.867,26.336,4.634,39.445
			l-12.979,5.374l-22.059-50.344c0,0-14.427-30.404-22.468-57.362c-8.041-26.957-10.697-95.006-10.697-95.006l19.821,0.015
			c0,0,0.4,0.671,1.452,1.394"
      />
      <path
        fill="#F5CEBA"
        d="M862.685,475.697c0.214,4.893,1.281,8.775,2.497,11.686c2.177,5.21,7.616,8.037,12.729,6.359
			c1.727-0.567,3.243-1.584,4.001-3.342c2.426-5.628-2.426-16.039-6.065-18.853c-1.427-1.104-2.511-4.133-3.303-7.406
			c-0.003-0.073-3.222-6.428-3.719-14.921l-12.979,5.374l6.128,13.985C862.307,470.677,862.569,473.038,862.685,475.697z"
      />
    </g>
    <path
      fill="#FFC233"
      d="M779.169,176.867c0,0-15.434-24.708-17.126-28.914c0,0,11.464-12.277,20.276-11.244
		c0,0,6.184,17.113,21.448,17.758c6.757,0.286,28.286,6.722,32.437,9.212c4.151,2.491,19.896,25.789,19.896,25.789
		s-8.984,13.968-15.351,13.935c0,0-8.052-10.991-10.441-10.398c-10.26,2.547-11.264,47.473-3.164,61.957
		c0.689,1.231-43.518,6.537-49.57,0.916c7.151-16.47,7.545-26.618-5.987-58.349C767.358,187.61,779.169,176.867,779.169,176.867z"
    />
    <path
      fill="#F5CEBA"
      d="M816.783,132.26c0,0-2.552,13.201-2.041,18.408c0.511,5.207-0.847,12.955-11.869,13.01
		c-11.021,0.055-3.192-12.303-2.154-17.405c1.038-5.102,1.284-20.898,1.284-20.898L816.783,132.26z"
    />
    <path
      fill="#D8A08D"
      d="M801.502,138.776c0.172-4.321,0.228-8.972,0.228-8.972l14.78,6.885c0,0-0.896,3.055-1.42,6.494
		C808.62,144.483,804.306,142.78,801.502,138.776z"
    />
    <path
      fill="#F5CEBA"
      d="M823.266,99.398c0,0,6.879,2.245,8.547,9.415c1.668,7.17-1.427,25.555-15.683,29.779
		c-14.256,4.225-19.688-10.457-16.279-21.816C803.26,105.417,808.621,92.209,823.266,99.398z"
    />
    <path
      fill="#F5CEBA"
      d="M796.914,115.236c0.132,2.915,1.572,5.156,3.214,5.006c1.643-0.151,2.868-2.635,2.736-5.551
		c-0.132-2.915-1.572-5.156-3.214-5.006C798.006,109.837,796.781,112.322,796.914,115.236z"
    />
    <path
      fill="#F5CEBA"
      d="M827.409,123.619c-1.776,2.529-1.918,5.532-0.316,6.706c1.602,1.174,4.339,0.075,6.115-2.455
		c1.776-2.53,1.918-5.532,0.316-6.706C831.922,119.99,829.185,121.089,827.409,123.619z"
    />
    <path
      fill="#C99465"
      d="M845.008,98.789c0,0-7.178,9.805-18.61,5.116c-11.659-4.782-13.567,3.568-25.483-5.939
		c0,0-0.087-10.396,11.576-14.001c7.718-2.386,19.291-1.262,25.269,3.349C839.773,88.867,842.326,96.21,845.008,98.789z"
    />
    <path
      fill="#C99465"
      d="M832.102,95.665c0,0-14.442,7.729,8.766,24.866c0,0,1.625-4.159,0.102-8.947
		C839.448,106.796,832.102,95.665,832.102,95.665z"
    />
    <path
      fill="#F5CEBA"
      d="M782.917,482.933c8.73,2.384,15.183,0.763,18.793-0.786c0.931,2.807,2.165,6.33,1.511,8.361
		c-0.801,2.487-1.893,3.689-2.9,3.717c-7.974,0.223-29.259-0.114-35.339-0.029c-0.849,0.012-1.317-1.044-0.771-1.737
		C765.717,490.542,782.267,483.356,782.917,482.933z"
    />
    <path
      fill="#F5CEBA"
      d="M864.191,484.357c7.93,1.539,13.207-3.397,15.952-7.093c2.015,4.187,3.492,10.126,2.009,13.565
		c-1.305,3.029-4.854,3.368-7.859,3.487c-3.176,0.126-6.395-1.871-7.971-4.815C865.586,488.126,864.827,486.414,864.191,484.357z"
    />
    <g>
      <g>
        <path
          fill="#FD5A62"
          d="M804.902,125.138c0,0-1.491,5.992,4.123,8.164c5.565,2.152,8.543-3.264,8.543-3.264L804.902,125.138z"
        />
        <path
          fill="#FD5A62"
          d="M817.568,130.037c0,0-4.536-5.069-12.666-4.899C804.902,125.138,810.968,130.092,817.568,130.037z"
        />
        <path
          fill="#FFFFFF"
          d="M804.917,125.098l12.666,4.899c0,0-2.006,3.907-7.909,1.624
				C804.44,129.596,804.917,125.098,804.917,125.098z"
        />
      </g>
    </g>
  </g>
);

export default Sarah;
