import React from "react";
import { Trans } from "gatsby-plugin-react-i18next";
import styled, { keyframes } from "styled-components";
import { RHAP_UI } from "react-h5-audio-player";
import { useTranslation } from "gatsby-plugin-react-i18next";
import useInViewFallback from "../../util/intersectionFallback";

import ms from "../../util/ms";

import MobileSarahSVG from "../../assets/images/svg/mobilehomestudiosarah.svg";
import HomeSarahSVG from "../../assets/images/svg/homestudiosarah2.svg";
import IcoMic from "../../assets/images/svg/icon-mic.svg";
import IcoInterface from "../../assets/images/svg/icon-interface.svg";
import IcoHeadphones from "../../assets/images/svg/icon-headphones.svg";
import IcoWave from "../../assets/images/svg/icon-soundwave.svg";
import IcoMac from "../../assets/images/svg/icon-macbook.svg";
import homeStudioDryRead from "../../assets/audio/Home_Dry_Read_KUS.mp3"; // Home_Dry_Read.mp3

import {
  Content,
  Section,
  FeatureDuo,
  Visualization,
  Text,
  Headline,
  Subline,
  WaveDividerFour,
  Player as HomeStudioDemo,
} from "../SubComponents";

const leafAnimation = keyframes`
  50% { transform: skewX(8deg); }
`;
const leafAnimationTwo = keyframes`
  40% { transform: skewX(6deg); }
`;
const leafAnimationThree = keyframes`
  60% { transform: skewX(7deg); }
  80% { transform: skewX(5deg); }
`;

const HomeStudioSarah = styled(HomeSarahSVG)`
  &.inView {
    .animatedLeafOne {
      animation: ${leafAnimation} ease-in-out 7s infinite;
    }
    .animatedLeafTwo {
      animation: ${leafAnimationTwo} ease-in-out 6s infinite;
    }
    .animatedLeafThree {
      animation: ${leafAnimationThree} ease-in-out 7s infinite;
    }
  }
`;

const MobileStudioSarah = styled(MobileSarahSVG)`
  transform: translate(50px, -130px);
  width: 9em;
  height: auto;
  bottom: 0;
  right: 0;
  z-index: 0;
  position: absolute;
  ${({ theme }) => theme.media.tabletLandscape`
    display: none;
  `};
`;

const HomeStudioSetup = styled.div`
  display: inline-grid;
  grid-template-columns: 1.5em auto;
  gap: 1em 1.25em;
  align-items: center;
  padding: 0.5em 0 2em;
  font-size: ${ms(1)};
  text-align: left;

  > svg {
    width: 1.5em;
    height: auto;
    ${({ theme }) => theme.media.phoneLandscape`
      width: 3em;
    `};
  }
  ${({ theme }) => theme.media.phoneLandscape`
    grid-template-columns: 3em auto;
    gap: 1em 2.25em;
  `};
`;
const BulletMicrophone = styled(IcoMic)`
  fill: ${({ theme }) => theme.colors.iconColor};
`;
const BulletAudio = styled(IcoInterface)`
  fill: ${({ theme }) => theme.colors.iconColor};
`;
const BulletHeadphones = styled(IcoHeadphones)`
  fill: ${({ theme }) => theme.colors.iconColor};
`;
const BulletSoftware = styled(IcoWave)`
  fill: ${({ theme }) => theme.colors.iconColor};
`;
const BulletLaptop = styled(IcoMac)`
  fill: ${({ theme }) => theme.colors.iconColor};
`;

const HomeStudioVisualization = styled(Visualization)`
  display: none;
  ${({ theme }) => theme.media.tabletLandscape`
    display: unset;
    // transform: translate(-300px, -83px) scale(1.4);
  `};
`;

const HomeStudioText = styled(Text)`
  z-index: 1;
`;

const lightColor = ({ theme }) => theme.backgroundColors.lightColor;

const SectionHomeStudio = () => {
  const { t } = useTranslation();
  const { ref, inView } = useInViewFallback();

  return (
    <Section id={"homestudio"} gapWidth={4} bgColor={lightColor}>
      <Content>
        <FeatureDuo columnRatio={[1, 1]}>
          <HomeStudioVisualization ref={ref}>
            <HomeStudioSarah className={inView && "inView"} />
          </HomeStudioVisualization>
          <HomeStudioText>
            <Headline>
              <Trans>Home Studio</Trans>
            </Headline>
            <Subline>
              <Trans>
                From the comforts of my home studio I can connect with clients
                all over the world. You can tune in live via SourceConnect Now,
                Skype, Zoom, FaceTime or any other online call service of your
                choice, or I can self-direct. I'll then deliver the finished
                audio raw or fully edited - up to you!
              </Trans>
            </Subline>
            <HomeStudioSetup>
              <BulletMicrophone /> <span>Audio Technica AT4050</span>
              <BulletAudio /> <span>Focusrite Clarett 2Pre USB</span>
              <BulletHeadphones /> <span>Beyerdynamic DT-770 PRO</span>
              <BulletSoftware /> <span>Adobe Audition</span>
              <BulletLaptop /> <span>Macbook Pro</span>
            </HomeStudioSetup>
            <HomeStudioDemo
              autoPlay={false}
              preload="none"
              layout="horizontal-reverse"
              showJumpControls={false}
              customVolumeControls={[]}
              customAdditionalControls={[]}
              customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
              src={homeStudioDryRead}
              footer={t("Home Studio Dry Read")}
            />
          </HomeStudioText>
        </FeatureDuo>
        <MobileStudioSarah />
      </Content>
      <WaveDividerFour bgColor="bright" />
    </Section>
  );
};

export default SectionHomeStudio;
