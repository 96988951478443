import { useInView } from "react-intersection-observer";

const useInViewFallback = () => {
  const hookResult = useInView();
  if (typeof IntersectionObserver === "undefined") {
    return [undefined, true];
  }
  return hookResult;
};

export default useInViewFallback;
