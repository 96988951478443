import React from "react";
import { animated } from "react-spring";
import { PlacementGroup } from "../../../../SubComponents";

const topStyles = {
  adidas: {
    regular: "translate(-55px, 5px) scale(1.15)",
    phone: "translate(10px, -70px) scale(1.3)",
  },
  googlePixel: {
    regular: "translate(475px, 35px) scale(1.2)",
    phone: "translate(660px, -45px) scale(1.4)",
  },
  uberEats: {
    regular: "translate(215px,375px) scale(0.85)",
    phone: "translate(230px,-30px) scale(1.1)",
  },
  amazon: {
    regular: "translate(960px, 45px) scale(0.4)",
    phone: "translate(570px, 155px) scale(0.4)",
  },
  bayer: {
    regular: "translate(1500px, 190px) scale(1.5)",
    phone: "translate(1480px, 125px) scale(1.45)",
  },
  verizon: {
    regular: "translate(1145px, 455px) scale(0.9)",
    phone: "translate(1460px,-45px) scale(0.9)",
    small: "translate(959px,445px) scale(2.3)",
  },
};

const Bayer = () => (
  <PlacementGroup pos={topStyles.bayer}>
    <path
      d="m35.9 11.3h4.4c.5 0 .9-.4.9-.9s-.4-.9-.9-.9h-4.4zm0 4.2h4.5c.6 0 1-.4 1-1s-.4-1-1-1h-4.5zm7.1-3.2c.6.6 1 1.4 1 2.3 0 1.8-1.4 3.2-3.2 3.2h-7.3v-10.5h7.2c1.7 0 3.1 1.4 3.1 3.1-.1.7-.4 1.4-.8 1.9zm1.7 18h-2.7l-.8-1.8h-5.9l-.8 1.8h-2.7l5.2-10.5h2.4zm-6.5-7.8-1.8 3.8h3.6zm3.6 10.1h3l-5.3 6.8v3.7h-2.5v-3.7l-5.3-6.8h3l3.6 4.8zm13.9 0v2.3h-7v1.8h6.8v2.3h-6.8v2h7v2.3h-9.5v-10.7zm7.7 6.5h-1.9v4h-2.5v-10.5h6.4c1.8 0 3.2 1.5 3.2 3.3 0 1.5-1 2.7-2.3 3.1l3.1 4.1h-3zm1.8-4.3h-3.6v2h3.6c.6 0 1-.5 1-1s-.5-1-1-1zm-32.4 8.3h-2.7l-.8-1.8h-5.9l-.8 1.8h-2.7l5.3-10.5h2.4zm-6.5-7.8-1.8 3.8h3.7zm-15.9 1.3h4.4c.5 0 .9-.4.9-.9s-.4-.9-.9-.9h-4.4zm0 4.2h4.5c.6 0 1-.4 1-1s-.4-1-1-1h-4.5zm7.1-3.2c.6.6 1 1.4 1 2.3 0 1.8-1.4 3.2-3.2 3.2h-7.4v-10.5h7.2c1.7 0 3.1 1.4 3.1 3.1 0 .7-.3 1.4-.7 1.9zm25.5 7.7v2.3h-7v1.8h6.8v2.3h-6.8v2h7v2.3h-9.5v-10.7zm-1.8 16.3c0-.6-.4-1-1-1h-4.3v2h4.3c.6 0 1-.4 1-1zm-7.8 7.3v-10.5h7c1.8 0 3.2 1.5 3.2 3.3 0 1.4-.8 2.5-2 3l3.2 4.2h-3l-3-4h-2.9v4z"
      fill="#10384f"
    />
    <path
      d="m76.1 35.6c-1.2-19.8-17.7-35.6-37.9-35.6s-36.7 15.8-37.9 35.6c0 .8.1 1.6.2 2.4.8 6.6 3.3 12.7 7.1 17.8 6.9 9.4 18 15.5 30.6 15.5-17.6 0-32-13.7-33.2-30.9-.1-.8-.1-1.6-.1-2.4s0-1.6.1-2.4c1.2-17.2 15.6-30.9 33.2-30.9 12.6 0 23.7 6.1 30.6 15.5 3.8 5.1 6.3 11.2 7.1 17.8.1.8.2 1.6.2 2.3 0-.8.1-1.6.1-2.4 0-.7 0-1.5-.1-2.3"
      fill="#89d329"
    />
    <path
      d="m.3 40.4c1.2 19.8 17.7 35.6 37.9 35.6s36.7-15.8 37.9-35.6c0-.8-.1-1.6-.2-2.4-.8-6.6-3.3-12.7-7.1-17.8-6.9-9.4-18-15.5-30.6-15.5 17.6 0 32 13.7 33.2 30.9.1.8.1 1.6.1 2.4s0 1.6-.1 2.4c-1.2 17.3-15.6 30.9-33.2 30.9-12.6 0-23.7-6.1-30.6-15.5-3.8-5.1-6.3-11.2-7.1-17.8-.1-.8-.2-1.6-.2-2.3 0 .8-.1 1.6-.1 2.4 0 .7 0 1.5.1 2.3"
      fill="#00bcff"
    />
  </PlacementGroup>
);
const Amazon = () => (
  <PlacementGroup pos={topStyles.amazon}>
    <path
      d="m373.64,141.94c-83.87,60.8-215.04,48.54-287.49-20.77-3.28-2.96-.34-7,3.59-4.69,80.36,47.19,188.66,56.37,278.82,17.28,5.93-2.52,10.88,3.88,5.09,8.18"
      fill="#f90"
    />
    <path
      d="m388.19,125.29c-5.77-9.26-54.57,4.88-41.71-6.08,20-14.08,52.83-10.02,56.65-5.3,3.83,4.75-1,37.65-19.79,53.35-13.98,9.54,12.44-33.85,4.85-41.97"
      fill="#f90"
    />
    <path
      d="m348.14,19.82V6.13c0-2.07,1.57-3.46,3.46-3.46h61.27c1.97,0,3.54,1.42,3.54,3.46v11.72c-.49,5.29-33.21,48.29-36.36,53.93,9.85.03,35.3,1.24,38.2,12.82,0,0,0,14.6,0,14.6,0,1.99-2.2,4.33-4.51,3.12-18.85-9.88-43.89-10.96-64.73.1-2.12,1.15-4.35-1.15-4.35-3.15.71-6.87-1.83-16.58,2.25-23.28,0,0,36.78-52.75,36.78-52.75h-32.01c-1.97,0-3.54-1.39-3.54-3.43"
      fill="#221F1F"
    />
    <path
      d="m124.64,105.21h-18.64c-1.78-.13-3.2-1.47-3.33-3.17V6.37c0-1.91,1.6-3.43,3.59-3.43h17.38c1.81.08,3.25,1.47,3.38,3.2v12.51h.34c7.95-23.7,40.5-23.85,48.74,0,7.43-20.55,34.58-23.79,47.32-7.26,5.9,8.05,4.69,19.74,4.69,29.99,0,0-.03,60.38-.03,60.38,0,1.91-1.6,3.46-3.59,3.46h-18.61c-1.86-.13-3.36-1.63-3.36-3.46v-50.7c.04-9.25,2.37-27.48-11.48-26.16-12.91.15-13.32,16.23-12.87,26.16,0,0,0,50.7,0,50.7,0,1.91-1.6,3.46-3.59,3.46,0,0-18.61,0-18.61,0-1.89-.13-3.36-1.63-3.36-3.46l-.03-50.7c0-10.67,1.76-26.37-11.48-26.37s-12.87,15.31-12.87,26.37v50.7c0,1.91-1.6,3.46-3.59,3.46"
      fill="#221F1F"
    />
    <path
      d="m469.15.92c56.61-2.43,56.23,108.32,0,106.28-55.69,2.27-55.76-108.79,0-106.28m.16,19.53c-13.74,0-14.6,18.72-14.6,30.39s-.18,36.65,14.45,36.65,15.13-20.13,15.13-32.4c-.59-11.62,1.25-34.74-14.97-34.63"
      fill="#221F1F"
    />
    <path
      d="m547.65,105.21h-18.56c-1.86-.13-3.36-1.63-3.36-3.46l-.03-95.69c.16-1.76,1.7-3.12,3.59-3.12h17.28c1.63.08,2.96,1.18,3.33,2.67v14.63h.34c6.61-21.97,34.41-26.5,47.17-8.05,4.88,7.66,4.88,20.53,4.88,29.78v60.22c-.21,1.68-1.76,3.01-3.59,3.01h-18.69c-1.7-.13-3.12-1.39-3.3-3.01v-51.96c0-10.46,1.21-25.77-11.67-25.77-12.12.5-13.96,16-13.74,25.77,0,0,0,51.52,0,51.52-.03,1.91-1.65,3.46-3.64,3.46"
      fill="#221F1F"
    />
    <g>
      <path
        d="m299.29,59.51v-4.04c-33.29-2.7-34.45,31.14-16.39,32.27,13.42-.34,17.38-16.95,16.39-28.24m18.8,45.43c-6.21,3.93-12.6-9.23-15.1-11.98-18.14,21.55-58.89,18.86-58.62-15.49-.25-31.04,31.4-35.39,54.92-36.94-.14-5.21.77-11.34-2.25-15.91-6.9-8.92-23.5-5.12-25.46,6.34-.31,1.68-1.55,3.33-3.22,3.41l-18.09-1.94c-1.52-.34-3.2-1.57-2.78-3.91,4.17-21.92,23.96-28.52,41.68-28.52,46.57.8,34.87,37.43,36.28,70.39,2.73,26.57,20.4,11.24-7.31,34.61l-.05-.05"
        fill="#221F1F"
      />
    </g>
    <path
      d="m54.93,59.51v-4.04c-33.29-2.7-34.45,31.14-16.39,32.27,13.42-.34,17.38-16.95,16.39-28.24m18.8,45.43c-6.21,3.93-12.6-9.23-15.1-11.98C40.49,114.52-.27,111.83,0,77.47c-.25-31.04,31.4-35.39,54.92-36.94-.14-5.21.77-11.34-2.25-15.91-6.9-8.92-23.5-5.12-25.46,6.34-.31,1.68-1.55,3.33-3.22,3.41l-18.09-1.94c-1.52-.34-3.2-1.57-2.78-3.91C7.29,6.61,27.08,0,44.81,0c46.57.8,34.87,37.43,36.28,70.39,2.73,26.57,20.4,11.24-7.31,34.61l-.05-.05"
      fill="#221F1F"
    />
    <path
      d="m373.64,141.94c-83.87,60.8-215.04,48.54-287.49-20.77-3.28-2.96-.34-7,3.59-4.69,80.36,47.19,188.66,56.37,278.82,17.28,5.93-2.52,10.88,3.88,5.09,8.18"
      fill="#f90"
    />
    <path
      d="m388.19,125.29c-5.77-9.26-54.57,4.88-41.71-6.08,20-14.08,52.83-10.02,56.65-5.3,3.83,4.75-1,37.65-19.79,53.35-13.98,9.54,12.44-33.85,4.85-41.97"
      fill="#f90"
    />
    <path
      d="m348.14,19.82V6.13c0-2.07,1.57-3.46,3.46-3.46h61.27c1.97,0,3.54,1.42,3.54,3.46v11.72c-.49,5.29-33.21,48.29-36.36,53.93,9.85.03,35.3,1.24,38.2,12.82,0,0,0,14.6,0,14.6,0,1.99-2.2,4.33-4.51,3.12-18.85-9.88-43.89-10.96-64.73.1-2.12,1.15-4.35-1.15-4.35-3.15.71-6.87-1.83-16.58,2.25-23.28,0,0,36.78-52.75,36.78-52.75h-32.01c-1.97,0-3.54-1.39-3.54-3.43"
      fill="#221F1F"
    />
    <path
      d="m124.64,105.21h-18.64c-1.78-.13-3.2-1.47-3.33-3.17V6.37c0-1.91,1.6-3.43,3.59-3.43h17.38c1.81.08,3.25,1.47,3.38,3.2v12.51h.34c7.95-23.7,40.5-23.85,48.74,0,7.43-20.55,34.58-23.79,47.32-7.26,5.9,8.05,4.69,19.74,4.69,29.99,0,0-.03,60.38-.03,60.38,0,1.91-1.6,3.46-3.59,3.46h-18.61c-1.86-.13-3.36-1.63-3.36-3.46v-50.7c.04-9.25,2.37-27.48-11.48-26.16-12.91.15-13.32,16.23-12.87,26.16,0,0,0,50.7,0,50.7,0,1.91-1.6,3.46-3.59,3.46,0,0-18.61,0-18.61,0-1.89-.13-3.36-1.63-3.36-3.46l-.03-50.7c0-10.67,1.76-26.37-11.48-26.37s-12.87,15.31-12.87,26.37v50.7c0,1.91-1.6,3.46-3.59,3.46"
      fill="#221F1F"
    />
    <path
      d="m469.15.92c56.61-2.43,56.23,108.32,0,106.28-55.69,2.27-55.76-108.79,0-106.28m.16,19.53c-13.74,0-14.6,18.72-14.6,30.39s-.18,36.65,14.45,36.65,15.13-20.13,15.13-32.4c-.59-11.62,1.25-34.74-14.97-34.63"
      fill="#221F1F"
    />
    <path
      d="m547.65,105.21h-18.56c-1.86-.13-3.36-1.63-3.36-3.46l-.03-95.69c.16-1.76,1.7-3.12,3.59-3.12h17.28c1.63.08,2.96,1.18,3.33,2.67v14.63h.34c6.61-21.97,34.41-26.5,47.17-8.05,4.88,7.66,4.88,20.53,4.88,29.78v60.22c-.21,1.68-1.76,3.01-3.59,3.01h-18.69c-1.7-.13-3.12-1.39-3.3-3.01v-51.96c0-10.46,1.21-25.77-11.67-25.77-12.12.5-13.96,16-13.74,25.77,0,0,0,51.52,0,51.52-.03,1.91-1.65,3.46-3.64,3.46"
      fill="#221F1F"
    />
    <g>
      <path
        d="m299.29,59.51v-4.04c-33.29-2.7-34.45,31.14-16.39,32.27,13.42-.34,17.38-16.95,16.39-28.24m18.8,45.43c-6.21,3.93-12.6-9.23-15.1-11.98-18.14,21.55-58.89,18.86-58.62-15.49-.25-31.04,31.4-35.39,54.92-36.94-.14-5.21.77-11.34-2.25-15.91-6.9-8.92-23.5-5.12-25.46,6.34-.31,1.68-1.55,3.33-3.22,3.41l-18.09-1.94c-1.52-.34-3.2-1.57-2.78-3.91,4.17-21.92,23.96-28.52,41.68-28.52,46.57.8,34.87,37.43,36.28,70.39,2.73,26.57,20.4,11.24-7.31,34.61l-.05-.05"
        fill="#221F1F"
      />
    </g>
    <path
      d="m54.93,59.51v-4.04c-33.29-2.7-34.45,31.14-16.39,32.27,13.42-.34,17.38-16.95,16.39-28.24m18.8,45.43c-6.21,3.93-12.6-9.23-15.1-11.98C40.49,114.52-.27,111.83,0,77.47c-.25-31.04,31.4-35.39,54.92-36.94-.14-5.21.77-11.34-2.25-15.91-6.9-8.92-23.5-5.12-25.46,6.34-.31,1.68-1.55,3.33-3.22,3.41l-18.09-1.94c-1.52-.34-3.2-1.57-2.78-3.91C7.29,6.61,27.08,0,44.81,0c46.57.8,34.87,37.43,36.28,70.39,2.73,26.57,20.4,11.24-7.31,34.61l-.05-.05"
      fill="#221F1F"
    />
  </PlacementGroup>
);
const UberEats = () => (
  <PlacementGroup pos={topStyles.uberEats}>
    <path
      d="m182.94,0h38.51v9.42h-28.11v13.38h27.33v9.1h-27.33v13.61h28.11v9.42h-38.51V0Zm144.86,55.92c19.91,1.05,26.38-20.83,6.28-24.87,0,0-8.72-1.78-8.72-1.78-19.24-4.19,7.71-14.06,9.71-1.89h10.21c1.36-17.57-37.68-19.57-37.2-1.12-1.1,9.61,14.26,12.08,22.78,13.88,16.04,4.59-10.61,13.58-13.31,1.7h-10.27c1.5,8.46,7.77,14.09,20.52,14.09h0Zm-23.49-1.03c-29.43,2.21-20.38-10.16-21.83-31.34h-7.77v-9.2h7.81V2.82h10.27v11.54h11.52v9.2h-11.52v18.95c-.25,4.98,8.65,2.7,11.52,3.19v9.2Zm-44.29-40.52v3.66c-12.98-11.27-34.86-1.06-34.68,16.14-.94,17.58,21.42,28.59,34.68,17.04v3.67h10.24V14.36l-10.24.02Zm-12.09,32.65c-16.2.11-16.48-24.52-.28-24.78,16.19-.14,16.54,24.67.26,24.76l.02.02Z"
      fill="#05C167"
    />
    <path d="m160.08,21.96c-13.67-.59-7.51,25.1-8.81,32.93h-7.87V14.93h7.77v4.88c2.43-4.41,7.51-5.57,12.19-5.16v7.32h-3.29Zm-22.11,12.95c.39-27.73-40.42-26.83-40.39-.12-.96,20.13,27.16,28.67,38.42,12.5l-5.71-4.18c-6.55,9.84-23.32,6.35-24.72-5.63h32.42l-.02-2.57Zm-32.23-3.67c2.53-13.36,21.85-13.43,24.27,0h-24.27Zm-33.07-17.17c-5.31,0-10.4,2.13-14.13,5.91V0h-7.87v54.9h7.77v-5.07c31.16,24.87,54.08-32.32,14.23-35.75Zm-.57,34.72c-18.1,0-18.23-27.52-.13-27.68,18.08-.03,18.27,27.58.13,27.65v.04Zm-50.41-.38c7.59,0,13.49-5.82,13.49-14.55V0h8.15v54.9h-8.06v-5.19C22.69,62.7-.77,53.71.02,34.32c0,0,0-34.32,0-34.32h8.3v33.92c0,8.77,5.65,14.5,13.36,14.5Z" />
  </PlacementGroup>
);
const Adidas = () => (
  <PlacementGroup pos={topStyles.adidas}>
    <path
      d="M137.587,101.983c-6.966,0-11.087-3.562-11.401-8.694h6.652c0,1.711,0.943,3.981,5.063,4.12
	c2.846,0,4.435-1.711,4.435-3.003c-0.314-1.991-2.846-2.13-5.377-2.567c-2.287-0.262-4.522-0.838-6.652-1.693
	c-2.217-1.24-3.544-3.596-3.492-6.128c0-8.904,13.426-10.336,18.716-5.325c1.519,1.432,2.374,3.405,2.497,5.464h-6.32
	c0-1.746-0.943-2.951-2.689-3.3c-1.938-0.384-5.43-0.367-5.849,2.147c0,2.567,5.377,2.427,9.498,3.422
	c3.614,0.96,6.547,3.527,6.32,7.507c-0.192,3.387-2.252,5.936-5.395,7.106C141.655,101.756,139.595,101.983,137.587,101.983"
    />
    <path d="M16.334,59.907l21.841-12.693L49.89,67.31H20.454" />
    <path
      d="M6.208,88.906c0,4.854,5.063,8.398,9.62,6.896c4.33-1.432,6.233-6.669,3.911-10.563c-1.309-2.2-3.701-3.579-6.268-3.579
	C9.525,81.783,6.347,84.96,6.208,88.906 M13.156,102.018c-6.303,0-11.837-4.906-12.815-11.087
	c-0.995-6.303,2.933-12.501,8.991-14.404c3.789-1.187,8.171-0.594,11.418,1.693V76.23h6.338v25.211h-6.338V99.87
	C18.865,101.302,16.02,102.018,13.156,102.018"
    />
    <path d="M97.71,67.309L67.314,14.303L89.156,1.471l37.991,65.838" />
    <path
      d="M35.329,88.906c0,3.859,3.09,7.088,6.949,7.263c3.998,0.175,7.612-3.247,7.612-7.263c-0.14-3.963-3.335-7.123-7.28-7.263
	C38.612,81.661,35.364,84.908,35.329,88.906 M42.296,102.018c-7.158,0.018-12.972-5.779-12.972-12.937c0-0.052,0-0.122,0-0.175
	c0-7.455,6.268-13.269,13.653-12.955c2.479,0.105,4.889,0.838,6.914,2.27V67.536h6.32v33.905h-6.32V99.87
	C47.638,101.32,44.984,102.071,42.296,102.018"
    />
    <path d="M41.981,37.245L64.137,24.71l24.373,42.6H65.394v6.268h-6.303V67.31" />
    <path
      d="M144.867,73.769c-1.711-0.052-3.108-1.414-3.16-3.142c0.07-1.711,1.449-3.09,3.16-3.143c1.694-0.052,3.125,1.292,3.16,2.985
	c0,0.052,0,0.087,0,0.14c0.035,1.693-1.327,3.108-3.02,3.142C144.972,73.786,144.92,73.786,144.867,73.769 M144.867,67.938
	c-3.317,0-3.317,5.412,0,5.412C148.184,73.35,148.184,67.938,144.867,67.938"
    />
    <path
      d="M145.531,72.355l-0.629-1.432h-0.629v1.432h-0.471v-3.422h1.432c0.314,0,0.943,0.436,0.943,0.995
	c0.017,0.436-0.227,0.821-0.629,0.995l0.629,1.432 M144.902,69.387h-0.629v1.135h0.629
	C145.775,70.504,145.758,69.387,144.902,69.387 M103.053,88.906c0,3.859,3.09,7.088,6.949,7.263
	c3.998,0.175,7.612-3.247,7.612-7.263c-0.14-3.963-3.317-7.141-7.28-7.263C106.335,81.661,103.088,84.908,103.053,88.906
	 M110.019,102.018c-7.158,0-12.972-5.796-12.972-12.955c0-0.052,0-0.105,0-0.157c0-4.522,2.357-8.834,6.25-11.156
	c4.312-2.567,10.179-2.445,14.316,0.454v-1.991h6.023v25.211h-6.023v-1.571C115.344,101.32,112.708,102.071,110.019,102.018
	 M73.949,88.906c0,4.016,3.631,7.42,7.612,7.263c3.858-0.175,6.949-3.387,6.949-7.263c-0.035-4.016-3.282-7.246-7.28-7.263
	C77.283,81.783,74.088,84.96,73.949,88.906 M88.527,67.536h6.32v33.905h-6.32V99.87c-2.13,1.484-4.696,2.235-7.28,2.147
	c-6.407,0-12.169-4.766-13.147-11.156c-0.96-6.338,3.02-12.431,9.096-14.317c3.666-1.135,8.276-0.838,11.348,1.659V67.536
	L88.527,67.536L88.527,67.536z"
    />
    <polygon points="59.074,75.899 65.446,75.899 65.464,101.302 59.074,101.302 " />
  </PlacementGroup>
);
const GooglePixel = () => (
  <PlacementGroup pos={topStyles.googlePixel}>
    <path
      d="m69.22,31.13v20.88h-6.62V.46c7.79.62,23.16-2.37,28.94,4.46,10.47,9.22,2.27,26.98-11.38,26.21,0,0-10.94,0-10.94,0Zm0-24.34v18c7.29.03,20.33,1.78,20.3-9-.03-10.72-13.03-9.06-20.3-9Z"
      fill="#888888"
    />
    <path
      d="m110.65,4.56c.13,6.08-9.49,6.08-9.36,0-.13-6.08,9.49-6.08,9.36,0Zm-1.37,12.17v35.28h-6.62V16.73h6.62Z"
      fill="#888888"
    />
    <path
      d="m134.75,34.08l12.6,17.93h-7.78l-8.71-13.03-8.57,13.03h-7.78l12.46-17.93-12.17-17.35h7.49l8.71,12.46,8.28-12.46h7.78l-12.31,17.35Z"
      fill="#888888"
    />
    <path
      d="m166.72,53.16c-16.39.83-23.37-21.35-12.96-32.18,11.28-12.21,31.95-2.52,29.59,14.62h-27.72c-.25,11.73,16.01,16.1,21.31,5.18l5.9,2.88c-3.19,6.01-9.23,9.55-16.13,9.5h0Zm-10.58-23.04h20.23c-.78-11.23-18.76-11.17-20.23,0Z"
      fill="#888888"
    />
    <path d="m196.04.46v51.55h-6.62V.46h6.62Z" fill="#888888" />
    <path
      d="m51.52,27.24c0-1.86-.17-3.66-.48-5.38h-24.77v10.18h14.15c-.62,3.28-2.49,6.05-5.28,7.91v6.62h8.54c4.97-4.59,7.84-11.33,7.84-19.34Z"
      fill="#4285F4"
    />
    <path
      d="m26.27,52.94c7.1,0,13.05-2.34,17.41-6.36l-8.54-6.62c-7.94,5.68-20.93,1.28-23.6-8.3H2.79c-1.86,12.55,11.61,21.16,23.48,21.28Z"
      fill="#34A853"
    />
    <path
      d="m11.54,31.64c-1.98-5.11.66-11.47,0-16.78H2.79c-3.72,6.97-3.72,16.61,0,23.57,1.43-1.11,7.43-5.8,8.75-6.79Z"
      fill="#FBBC05"
    />
    <path
      d="m26.27,10.81c3.87,0,7.32,1.34,10.07,3.92l7.53-7.53C32.35-4.62,9.4-.17,2.79,14.85l8.75,6.79c2.08-6.22,7.89-10.83,14.73-10.83Z"
      fill="#EA4335"
    />
  </PlacementGroup>
);
const Verizon = () => (
  <PlacementGroup pos={topStyles.verizon}>
    <path
      fill="#D52B1E"
      d="M295.623,3L279.3,37.92l-6.139-13.231h-6.638l10.094,21.643h5.365L302.216,3L295.623,3L295.623,3z"
    />
    <path
      d="M225.6,67.839h13.14V43.786c0-5.501,3.183-9.366,7.911-9.366c4.547,0,6.911,3.183,6.911,7.775V67.84h13.14V39.695
	c0-9.549-5.729-16.278-15.005-16.278c-5.911,0-10.049,2.546-13.14,7.185h-0.273v-5.911h-12.731L225.6,67.839L225.6,67.839z
	 M199.773,23.507c-13.732,0-22.917,9.867-22.917,22.871c0,12.913,9.185,22.871,22.917,22.871s22.917-9.958,22.917-22.871
	C222.735,33.374,213.505,23.507,199.773,23.507z M199.683,59.518c-6.229,0-9.548-5.229-9.548-13.14c0-8.002,3.274-13.14,9.548-13.14
	c6.229,0,9.685,5.138,9.685,13.14C209.413,54.289,205.957,59.518,199.683,59.518z M139.39,67.839h36.921V57.472h-20.915v-0.273
	l20.007-22.417v-10.14h-36.011v10.412h20.234v0.273l-20.234,22.599L139.39,67.839L139.39,67.839z M122.521,67.839h13.231V24.688
	h-13.231V67.839z M91.829,67.839h13.186V48.014c0-9.003,5.411-13.004,13.641-11.867h0.273V24.78
	c-0.682-0.273-1.455-0.318-2.683-0.318c-5.138,0-8.594,2.365-11.549,7.412h-0.273v-7.185H91.829V67.839L91.829,67.839z
	 M67.639,59.563c-5.82,0-9.366-3.774-10.049-9.594h31.101c0.091-9.275-2.365-16.687-7.503-21.325
	c-3.638-3.365-8.412-5.229-14.504-5.229c-13.004,0-22.007,9.867-22.007,22.78c0,13.004,8.594,22.917,22.871,22.917
	c5.411,0,9.685-1.455,13.231-3.865c3.774-2.592,6.502-6.411,7.23-10.185H75.369C74.142,57.881,71.504,59.563,67.639,59.563z
	 M66.957,32.827c4.638,0,7.821,3.456,8.367,8.503H57.682C58.727,36.238,61.501,32.827,66.957,32.827L66.957,32.827z M18.396,67.839
	h13.823l15.005-43.151H33.992L25.58,52.425h-0.182l-8.412-27.737H3.391L18.396,67.839z M122.521,9.32h13.231v11.731h-13.231V9.32z"
    />
  </PlacementGroup>
);

const LogosLayerTop = (props) => (
  <animated.g {...props}>
    <Adidas />
    <GooglePixel />
    <UberEats />
    <Amazon />
    <Bayer />
    <Verizon />
  </animated.g>
);

export default LogosLayerTop;
