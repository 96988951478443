import React from "react";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";
import styled from "styled-components";

import {
  Actions,
  Button,
  Content,
  Section,
  Text,
  Headline,
  // Subline,
  WaveFooter,
} from "../SubComponents";

const StyledButton = styled(Button)`
  padding-left: 1.35em;
  padding-right: 1.35em;
`;

const PaddedButton = () => {
  const { language } = useI18next();

  if (language === "en") {
    return (
      <StyledButton ghost={false} to={"/contact"} variant="secondary">
        <Trans>Let's talk business</Trans>
      </StyledButton>
    );
  } else {
    return (
      <Button ghost={false} to={"/contact"} variant="secondary">
        <Trans>Let's talk business</Trans>
      </Button>
    );
  }
};
const SectionRatesCTA = () => (
  <Section id={"rates"}>
    <Content>
      <Text verticalOnly>
        <Headline large>
          <Trans>Where to next?</Trans>
        </Headline>
        <Actions>
          <Button ghost={false} to="/rates" variant="primary">
            <Trans>Show me the money!</Trans>
          </Button>
          <PaddedButton>
            <Trans>Let's talk business</Trans>
          </PaddedButton>
        </Actions>
      </Text>
    </Content>
    <WaveFooter />
  </Section>
);

export default SectionRatesCTA;
