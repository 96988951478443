import React from "react";
import { useSpring } from "react-spring";
import { Trans } from "gatsby-plugin-react-i18next";
import {
  // Actions,
  // Button,
  Content,
  FeatureDuo,
  Headline,
  Section,
  Subline,
  Text,
  Visualization,
  WaveDividerSway,
} from "../SubComponents";

import PortProjectsWindow from "../SubComponents/LayerComponents/PortProjectsWindow";

const baseColor = ({ theme }) => theme.backgroundColors.baseColor;
const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];

const SectionClients = React.memo(() => {
  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 1200, friction: 140 },
  }));

  return (
    <Section
      id={"clients"}
      gapWidth={2}
      bgColor={baseColor}
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
    >
      <Content spaceBottom>
        <FeatureDuo verticalOnly spaceBottom>
          <Text verticalOnly>
            <Headline large>
              <Trans>Happy clients</Trans>
            </Headline>
            <Subline>
              <Trans>
                When we work together, no client is too small - or too big! But
                here are some names you might recognise:
              </Trans>
            </Subline>
          </Text>
          <Visualization>
            <PortProjectsWindow springProps={props} />
          </Visualization>
        </FeatureDuo>
      </Content>
      <WaveDividerSway bgColor="dark" />
    </Section>
  );
});

export default SectionClients;
